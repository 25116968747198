<template>
  <div class="container">
    <loader />
  </div>
</template>
<script>
import W9FormApi from '@/service/api'
import Loader from '../components/loader.vue'

export default {
  data () {
    return {
      showLoader: false
    }
  },
  components: {
    Loader
  },
  created () {
    this.loginUsingSso()
  },
  methods: {
    async loginUsingSso () {
      this.showLoader = true
      let tosId = this.$route.query.tosId
      console.log("query", this.$route.query)
      const formData = {
        pin: this.$route.query.pin,
        domain: this.$route.query.domain,
        sso_token: this.$route.query.sso_token,
        random: this.$route.query.random,
      };
      W9FormApi.login(formData)
      .then(res => {
        // change langauge from here
        this.$store.dispatch('savePin', formData.pin)
        this.$store.dispatch('saveTosId', tosId)
        this.$root.$i18n.locale = res.data.language
        this.$router.push({name: 'Form'})
        if (res.data.session._csrf) {
          this.$store.dispatch('saveCsrf', res.data.session._csrf)
        }
      })
      .catch(error => {
        this.showLoader = false
        if (error.response.data.error.status === 401) {
          this.$router.push({name: 'Unauthorized'})
        } else {
          this.$router.push({name: 'ServerError'})
        }
      })
    }
  }
}
</script>